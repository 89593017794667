import { NavLink, Route, Routes } from 'react-router-dom';
import { Col, Container, Navbar, Nav, Row } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import { lazy, Suspense } from 'react';
const Home = lazy(() => import('./pages/Home'));
const Connections = lazy(() => import('./pages/Connections'));
const Connect = lazy(() => import('./pages/Connect'));
const DataExtensions = lazy(() => import('./pages/DataExtensions'));
const Data = lazy(() => import('./pages/Data'));
const Automations = lazy(() => import('./pages/Automations'));
const Automation = lazy(() => import('./pages/Automation'));
const Queries = lazy(() => import('./pages/Queries'));
const Analyze = lazy(() => import('./pages/Analyze'));
const SoapClient = lazy(() => import('./pages/SoapClient'));

function App() {
	return (
		<>
			<Navbar bg="secondary" variant="dark" expand="lg">
				<Container>
					<Navbar.Brand>
						<NavLink to="/" style={{ color: 'inherit', textDecoration: 'inherit' }}>
							SFMC Toolbox
						</NavLink>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="toolbox-nav" />
					<Navbar.Collapse id="toolbox-nav">
						<Nav>
							<NavLink to="/connect" className="nav-link">
								Connect
							</NavLink>
						</Nav>
						<Nav>
							<NavLink to="/data" className="nav-link">
								Data
							</NavLink>
						</Nav>
						<Nav>
							<NavLink to="/automations" className="nav-link">
								Automations
							</NavLink>
						</Nav>
						<Nav>
							<NavLink to="/queries" className="nav-link">
								Queries
							</NavLink>
						</Nav>
						<Nav>
							<NavLink to="/analyze" className="nav-link">
								Analyze
							</NavLink>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<Suspense
				fallback={
					<Container style={{ marginTop: '1em' }}>
						<Row>
							<Col>
								<p>Loading...</p>
							</Col>
						</Row>
					</Container>
				}
			>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/data" element={<DataExtensions />} />
					<Route path="/data/:key/:page" element={<Data />} />
					<Route path="/connect" element={<Connections />} />
					<Route path="/connect/:name" element={<Connect />} />
					<Route path="/automations" element={<Automations />} />
					<Route path="/automations/automation/:key" element={<Automation />} />
					<Route path="/automations/:page" element={<Automations />} />
					<Route path="/queries" element={<Queries />} />
					<Route path="/analyze" element={<Analyze />} />
					<Route path="/analyze/:page" element={<Analyze />} />
					<Route path="/soap" element={<SoapClient />} />
				</Routes>
			</Suspense>
		</>
	);
}

export default App;
